<template>
<router-view></router-view>
</template>

<style lang="scss">

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 8px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /*border-radius: 10px;*/
  background   : #c1c1c1;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover{
  background-image: linear-gradient(180deg, #8ed4ed 0%, #6892fe 99%);
  cursor: pointer;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /*box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 10px;*/
  background   :#f1f1f1;
  cursor: pointer;
}
</style>
